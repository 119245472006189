import {createApp} from 'vue';
import mitt from 'mitt';
import App from './App.vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faSearch);

const emitter = mitt();
const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon);
app.provide('$emitter', emitter);
app.mount('#app');