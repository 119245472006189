<template>

  <div id="external-events" class="items vl-parent">
    <loading v-model:active="isLoading" :is-full-page="false" />

    <div class="search-container">
      <h2 v-show="!searchActive">Work Orders</h2>

      <input v-show="searchActive" ref="searchInput" v-model="searchQuery" :class="{ active: searchActive }"
        class="search-input" placeholder="Search..." type="text" @keyup.enter="onSearch" />

      <font-awesome-icon :class="{ active: searchActive }" class="search-icon" icon="search" @click="toggleSearch" />
    </div>

    <div class="alert">Moving Work Orders to the calendar will assign the WO to the selected vendor and technician,
      including the ETA.
    </div>

    <div class="items-container">
      <div v-for="workOrder in workOrders" :key="workOrder.Work_Order_Id" :data-work-order-id="workOrder.Work_Order_Id"
        :data-color="getItemColor(workOrder.Division_Id)" class="item external-event"
        :class="[getItemClass(workOrder.Division_Id)]" draggable="true">
        <div>{{ workOrder.Company_Name }}</div>
        <div>{{ workOrder.Site_Descriptor }}</div>
        <div>{{ workOrder.Work_Order_Code }}</div>
        <div>{{ workOrder.Subject }}</div>
        <div>
          <a :href="`${ownerUrl}/wo/workorder?id=${workOrder.Work_Order_Id}`" target="_blank">
            Open this WO
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';

export default {
  name: 'WorkOrders',
  components: { Loading },
  inject: ['$emitter'],
  data() {
    return {
      ownerUrl: process.env.VUE_APP_OWNER_URL,
      isLoading: true,
      workOrders: [],
      searchActive: false,
      searchQuery: '',
    };
  },
  created() {
    this.getWorkOrders();
  },
  mounted() {
    this.$emitter.on('onEventReceived', this.onEventReceived);
  },
  methods: {
    toggleSearch() {
      this.searchActive = !this.searchActive;
      if (this.searchActive) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      } else {
        this.searchQuery = '';
      }
    },
    onEventReceived() {
      this.getWorkOrders(this.searchQuery);
    },
    getWorkOrders(search = '') {
      this.isLoading = true;
      let endpoint = search !== '' ? '/api/v2/scheduler/work-orders?search=' + search : '/api/v2/scheduler/work-orders';

      axios.get(endpoint)
      .then((response) => {
        this.workOrders = response.data.data;
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.isLoading = false;
      });
    },
    onSearch() {
      if (this.searchQuery.trim() === '') {
        alert('Please enter a text to search for.');
      } else {
        this.getWorkOrders(this.searchQuery);
      }
    },
    getItemClass(Division_Id) {
      switch (Division_Id) {
        case 1361:
          return 'install';
        case 1329:
          return 'service';
        default:
          return 'sample';
      }
    },
    getItemColor(Division_Id) {
      switch (Division_Id) {
        case 1361: // install
          return '#FFF3E0';
        case 1329: // service
          return '#E0EBFC';
        default:
          return '#E0F7FA';
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .items {
    text-align: left;
    height: calc(100vh - 180px);

    .items-container {
      height: 100%;
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .item {
      border-radius: 8px;
      cursor: grab;
      padding: 16px;

      font-family: Nunito;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;

      background-color: #E0EBFC;
    }

    .install {
      color: #E65100;
      background-color: #FFF3E0;
    }

    .service {
      color: #001F3D;
      background-color: #E0EBFC;
    }
  }

  /* search */
  .search-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    h2 {
      margin-bottom: 12px;

      font-family: Nunito;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
    }

    .search-input {
      position: absolute;
      left: 0;
      width: 100%;
      opacity: 0;
      transition: opacity 0.3s;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      padding: 0.5rem 0.5rem 0.5rem 2rem;

      &:focus {
        outline: none;
      }

      &.active {
        opacity: 1;
      }
    }

    .search-icon {
      cursor: pointer;
      transition: transform 0.3s;

      &.active {
        margin-left: 0.5rem;
        transform: scaleX(-1);
      }
    }
  }

  .alert {
    font-family: Nunito;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    margin-bottom: 16px;
  }
</style>
