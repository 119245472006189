<template>
  <div class="container">
    <loading v-model:active="isLoading" :is-full-page="true" />

    <div v-if="!isLoading" class="column1">
      <work-orders></work-orders>
    </div>
    <div v-if="!isLoading" class="column2">
      <full-calendar :self-performing-id="selfPerformingId"></full-calendar>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import axios from 'axios';
import FullCalendar from './components/FullCalendar.vue';
import WorkOrders from "@/components/WorkOrders.vue";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('api_token')

export default {
  name: 'App',
  components: { WorkOrders, FullCalendar, Loading },
  data() {
    return {
      isLoading: true,
      apiToken: '',
      selfPerformingId: 0,
      envMode: process.env.NODE_ENV,
      apiUrl: process.env.VUE_APP_API_URL,
    }
  },
  created() {
    // console.log('ENV MODE', this.envMode)
    // console.log('Base API Url', this.apiUrl)

    // console.log('created')
    this.getAuthentication();
  },
  methods: {
    getAuthentication() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get("user_id");

      axios.post('/api/v2/one-login', { user_id: id })
      .then((response) => {  
        this.apiToken = response.data.api_token;
        this.selfPerformingId = response.data.self_performing_id;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.apiToken;
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
      });
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  color: #001F3D;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
}

#app {
  color: #2c3e50;
}

.container {
  display: flex;
  gap: 20px;
}

.column1 {
  width: 400px;
  padding: 16px;
  border-right: 1px solid #E4E6EB;
}

.column2 {
  flex: 1;
}
</style>
